
.q-menu
  @media screen and (min-width: 600px)
    width: auto !important
    max-width: 100vw !important
    max-height: calc(100vh - 55px) !important
  @media screen and (max-width: 599px)
    width: 100vw !important
.appHeaderContainer
  z-index: 1000
.search
  .q-field--outlined .q-field__control:before
    border: 2px solid #ececee !important
    border-radius: 8px
  .q-field--outlined .q-field__control
    border-radius: 8px
    padding: 0 22px
  .q-field__inner
    background: #f4f4f4
    border-radius: 8px
.fixedElement
  position: fixed
  top: 0
  width: 100%
  z-index: 100
  -webkit-transform: translateZ(0)
.mobile-header
  display: flex
  flex-direction: row
  height: 55px
  align-items: center
  justify-content: space-evenly
  background-color: white
  box-shadow: 1px 2px 3px 0 rgba(24,25,27,0.09)
  -webkit-box-shadow: 1px 2px 3px 0 rgba(24,25,27,0.09)
  -o-box-shadow: 1px 2px 3px 0 rgba(24,25,27,0.09)
  -moz-box-shadow: 1px 2px 3px 0 rgba(24,25,27,0.09)
.auth-btn, .auth-icon
  transition: all .33s
.auth-btn
  cursor: pointer
  font-size: 18px
.revealed
  background-color: rgba(255,255,255,0.94)
  transition: background-color 1s ease-in-out
.not-revealed
  background-color: rgba(255,255,255,0.01)
  transition: background-color 1s ease-in
.topHeaderText
  font-weight: 400
  font-size: 13px
  line-height: 1.25rem
  letter-spacing: .2px
  color: #626262
.linkHover
  border-radius: 16px
  &:hover
    background: #ffffff !important
    color: #4f57b5 !important
    transition: background-color 0.5s ease
.link
  cursor: pointer
  font-weight: 500
  font-size: 1rem
  line-height: 1.5rem
  text-transform: none
  letter-spacing: .2px
  height: 100%
  padding: 0 15px 0 15px
  display: flex
  align-items: center
  justify-content: center
  color: white
  transition: background-color .3s,color .3s
  white-space: normal
  border-radius: 16px
  text-decoration: none !important
  &:hover
    background: #ffffff !important
    color: #4f57b5 !important
    transition: background-color 0.5s ease
.menuName
  color: #4D4F53
  font-size: 18px
  font-family: 'Work Sans', sans-serif
.loginBox
  width: 330px
  height: 208px
  display: flex
  border-radius: 10px
  justify-content: center
  align-items: center
  border: 1px solid #e5e6e9
  padding: 10px 0px 10px 0px
  background: white
  .title
    color: #4D4F53
    font-size: 20px
    font-weight: 600
    font-family: 'Work Sans', sans-serif
    margin: 5px 0 5px 0
    width: 100%
    text-align: center
  .btn
    width: 300px
    border-radius: 6px
    height: 52px
    background: #DFE0E3
    background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
    display: flex
    justify-content: center
    align-items: center
    color: white
    text-transform: uppercase
    font-size: 15px
    font-weight: 600
    font-family: 'Work Sans', sans-serif
    margin: 10px 0 10px 0
    cursor: pointer
  .btn2
    width: 300px
    border-radius: 6px
    height: 52px
    background: #DFE0E3
    display: flex
    justify-content: center
    align-items: center
    color: #4D4F53
    text-transform: uppercase
    font-size: 15px
    font-weight: 600
    font-family: 'Work Sans', sans-serif
    margin: 10px 0 10px 0
    cursor: pointer
.auth-icon, .cart-icon, .menuName
  transition: all .33s
.shopping-cart-count
  margin-left: 6px
  background: #535458
  border-radius: 100px
  font-size: 10px
  font-weight: 600
  width: 28px
  padding: 2px
  display: flex
  color: white
  line-height: 12px
  justify-content: center
  align-items: center
.redText
  color: rgb(241,34,42) !important
.redHover
  border-radius: 8px
  &:hover
    color: rgb(241,34,42) !important
.productContainer
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  background: white
  border: 1px solid #e3e4e6
  padding: 25px 15px 25px 15px
  display: grid
  grid-template-columns: repeat(4,1fr)
  @media screen and (max-width: 1550px)
    grid-template-columns: repeat(3,1fr)
  @media screen and (max-width: 1200px)
    grid-template-columns: repeat(2,1fr)
  .item
    color: #4D4F53
    cursor: pointer
    width: 280px
    min-height: 40px
    border-radius: 5px
    font-size: 16px
    font-weight: 500
    padding: 12px 0px 12px 15px
    margin: 0px 7px 0 7px
    display: flex
    align-items: center
    &:hover
      color: #106CEC
      background: #DCE9FB
      transition: background-color 0.3s ease
.productFamilyContainer
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  border: 1px solid #e3e4e6
  width: 280px
  background: white
  padding: 10px
  .item
    color: #4D4F53
    cursor: pointer
    width: 240px
    min-height: 40px
    border-radius: 5px
    font-size: 15px
    font-weight: 600
    padding: 8px 0px 8px 15px
    margin: 0px 7px 0 7px
    display: flex
    align-items: center
    &:hover
      color: #106CEC
      background: #DCE9FB
      transition: background-color 0.3s ease
.headerShadow
  box-shadow: 1px 2px 3px 0px rgba(24,25,27,0.09)
  -webkit-box-shadow: 1px 2px 3px 0px rgba(24,25,27,0.09)
  -moz-box-shadow: 1px 2px 3px 0px rgba(24,25,27,0.09)
.searchContainer
  display: flex
  align-items: center
